body {
  margin: 0;
  font-display: 'auto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* noto-sans-latin-400-normal */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    url(https://cdn.jsdelivr.net/fontsource/fonts/noto-sans@latest/latin-400-normal.woff2)
      format('woff2'),
    url(https://cdn.jsdelivr.net/fontsource/fonts/noto-sans@latest/latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* noto-sans-latin-600-normal */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    url(https://cdn.jsdelivr.net/fontsource/fonts/noto-sans@latest/latin-600-normal.woff2)
      format('woff2'),
    url(https://cdn.jsdelivr.net/fontsource/fonts/noto-sans@latest/latin-600-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* righteous-latin-400-normal */
@font-face {
  font-family: 'Righteous';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    url(https://cdn.jsdelivr.net/fontsource/fonts/righteous@latest/latin-400-normal.woff2)
      format('woff2'),
    url(https://cdn.jsdelivr.net/fontsource/fonts/righteous@latest/latin-400-normal.woff)
      format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.bouncing-loader {
  display: flex;
  justify-content: left;
}

.bouncing-loader > div {
  width: 8px;
  height: 8px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-8px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
